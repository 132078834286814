"use strict";

function initMap() {

  // Specify features and elements to define styles.
  var styleArray = [{
    featureType: "all",
    stylers: [{ saturation: -80 }]
  }, {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ hue: "#00ffee" }, { saturation: 50 }]
  }, {
    featureType: "poi.business",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  }];

  // Create a map object and specify the DOM element for display.
  var map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: 45.5928, lng: -122.7601575 },
    scrollwheel: false,
    // Apply the map style array to the map.
    styles: styleArray,
    disableDefaultUI: true,
    draggable: false,
    zoom: 15,
    zoomControl: false
  });

  var center = map.getCenter();
  google.maps.event.addDomListener(window, 'resize', function () {
    map.setCenter(center);
  });
}